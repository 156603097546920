<template>
  <div class="main">
    <div class="main-title">
      <div>上海体育博物馆知识问答</div>
      <div>Q&As of Shanghai Sports Museum</div>
    </div>
    <div class="main-body">

      <div class="main-btn disabled" v-if="answered>0">今日答题次数已满，请明日再答</div>
      <div class="main-btn" @click="goQuestion" v-else>开始答题</div>

      <div class="main-btn" @click="goWx">兑换记录</div>
      <div><span @click="viewRule">查看规则</span></div>
    </div>
    <div class="main-btn main-btn-qt" @click="delQuestion" v-if="!hasjack">重新答题</div>
    <el-dialog :visible.sync="show" width="90%" :show-close="false">
      <div slot="title">
        <div>规则说明</div>
        <div>Game Rules</div>
      </div>
      <div v-html="detail.description" class="roll"></div>
      <div class="mvbutton btn" @click="confirmRule">已确认知晓活动规则<br />Rule Confirmed</div>
    </el-dialog>
  </div>
</template>

<script>

import api from '@/api/api'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import wx from "weixin-js-sdk";

export default {
  name: 'Main',
  data() {
    return {
      detail: {cats:[]},
      show: false,
      aid:'',
      hasjack: 1,
      canqr:false,
      minimalVRPoint: 100,
    }
  },
  computed: {
    // ...mapState(["aid"]),
    questionLink() {
      return {
        name: 'question',
        query: {
          aid: this.aid
        }
      }
    },
    answered() {
      if (this.detail.hasjack) {
        return 1
      } else if (this.detail.cats.length){
        return 0
      } else {
        return -1
      }
    }
  },
  methods: {
    ...mapMutations(["setAid"]),
    viewRule() {
      this.show = true
    },
    goWx()
    {
      try{
        let r = wx.miniProgram.reLaunch({
          url: '/pages/exchange/index'
        });
        console.log(r)
      } catch (e)
      {
        console.log(e,'e')
        this.$router.push({path:'/'});
      }
    },
    confirmRule() {
      this.show = !true
    },
    goList() {
      this.$router.push({
        name: 'Redemlist',
      })
    },
    goQuestion() {
      if (this.detail.score >= this.minimalVRPoint) {
        this.$router.push({
          name: 'Experiencelist'
        })
        return
      }
      if (this.aid) {
        this.$router.push({
          name: 'question',
          query:{aid: this.aid}
        })
      } else {
        if (!this.hasjack) {
          this.$router.push({
            name: 'Experiencelist'
          })
          return
        } else {
          this.$message({
            message: '今天的问题已经答完了',
            type: 'warning'
          })
        }
      }
    },
    delQuestion()
    {
      api.delQuest().then((d) => {
        if(d.data.status == 200)
        {
          this.$router.push({
            name: 'question',
            query:{aid: 933}
          })
        }
      })
    }
  },
  mounted () {
    api.detail(932).then(r => {
      this.detail = r.data.data
      if(this.detail.cats.length>0)
      {
        this.cats = this.detail.cats
        this.aid = this.detail.cats[0]
      }
      this.hasjack =  this.detail.hasjack
      if(this.detail.cats.length == 4)
      {
        this.hasjack = 1;
      }
      // this.setAid(this.detail.cats[0])
    })
    // 计算vr最小积分
    api.detail(934).then(d => {
      if(d.data.status == 200){
        d.data.data.list.forEach(item => {
          this.minimalVRPoint = Math.min(
            this.minimalVRPoint, 
            item.value > 0 ? item.value : 100
          )
        })
      }
    })
  }
}
</script>

<style scoped>
.main{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main .main-title{
  color: #fff;
  font-weight: 400;
  letter-spacing: 0;
  padding: 30px 0 80px 0;
}
.main .main-title>div:first-child{
  font-size: 28px;
}
.main .main-title>div:nth-child(2){
  font-size: 20px;
}

.main .roll{
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 13px;
  color: #333333;
  font-weight: 400;
  text-align: left;
}
.main .btn{
  width: 150px;
  height: 58.5px;
  font-size: 12px;
  margin: auto;
}

.main-btn {
  width: 280px;
  height: 56px;
  background-color: rgba(187, 138, 76, 1);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
  margin-bottom: 10px;

}
.main-btn a{
  text-decoration: none;
  color: #FFFFFF;
}

.main-btn-qt
{
  position: fixed;
  bottom: 10px;
}

.disabled{
  background-color: rgba(215, 215, 215, 1);
  box-shadow: 5px 5px 5px rgb(150, 150, 150);
}
</style>
